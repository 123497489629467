import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  increment,
  runTransaction,
} from "firebase/firestore";
import firebaseApp from "../../credentials";
import i18n from "../../i18n";
import {
  setStudents,
  addStudents,
  updateStudent,
  addPoints,
  subtractPoints,
  updateAllPoints,
  resetAllPoints,
  setRecords,
  addRecord,
  resetAllRecords,
  resetPet,
  resetAllBusinesses,
  resetAllAchievements,
  addDiamonds,
  subtractDiamonds,
  resetAllDiamonds,
  updateAllDiamonds,
} from "./studentsSlice";

import { updateAccountType } from "../settings/settingsThunks";

import { setRefs } from "../referrals/referralSlice";

import { setPets } from "../pets/petsSlice";

import { setGroups } from "../groups/groupsSlice";

import { setGoals } from "../goals/goalsSlice";
import { setPergaminos, setSentences } from "../tools/toolsSlice";
import { updateSettings } from "../settings/settingsSlice";

import { setEmails, setSavedClassrooms } from "../invites/inviteSlice";

import {
  setPositives,
  setNegatives,
  setExchanges,
} from "../behaviours/behavioursSlice";

import starterExchanges from "../../data/starterExchanges";
import starterExchangesEs from "../../data/starterExchangesEs";
import starterNegatives from "../../data/starterNegatives";
import starterNegativesEs from "../../data/starterNegativesEs";
import starterPositives from "../../data/starterPositives";
import starterPositivesEs from "../../data/starterPositivesEs";
import starterStudents from "../../data/starterStudents";
import starterStudentsEs from "../../data/starterStudentsEs";
import starterPergamino from "../../data/starterPergamino";
import starterPergaminoES from "../../data/starterPergaminoES";
import starterSettings from "../../data/starterSettings";
import starterDailySentence from "../../data/starterDailySentence";
import starterDailySentenceEs from "../../data/starterDailySentenceEs";
import starterPets from "../../data/starterPets";

const firestore = getFirestore(firebaseApp);

async function searchDocumentOrCreateDocument(idDocument) {
  const docuRef = doc(firestore, `usuarios/${idDocument}`);
  const consulta = await getDoc(docuRef);

  if (consulta.exists()) {
    let data = consulta.data();

    // MIGRATION code, to check if there's an user option saved on old localstorage
    //start MIGRATION CODE
    if (!data.pergaminos) {
      // If it doesn't exist, upload it based on language
      data.pergaminos =
        i18n.language === "es"
          ? [...starterPergaminoES]
          : [...starterPergamino];
      await updateDoc(docuRef, { pergaminos: data.pergaminos });
    }

    if (!data.dailySentences) {
      // If it doesn't exist, upload it based on language
      data.dailySentences =
        i18n.language === "es"
          ? [...starterDailySentenceEs]
          : [...starterDailySentence];
      await updateDoc(docuRef, { dailySentences: data.dailySentences });
    }

    if (!data.settings) {
      data.settings = { ...starterSettings };
      await updateDoc(docuRef, { settings: data.settings });
    } else if (!data.settings.lastPointsAddedDate) {
      data.settings.lastPointsAddedDate = "2024-01-12";
      await updateDoc(docuRef, { settings: data.settings });
    }

    if (data.referredCount === undefined || data.referredCount === null) {
      await updateDoc(docuRef, { referredCount: 0 });
    }
    //end MIGRATION CODE

    return data;
  } else {
    let initialData = {};
    const starterGroups = [];
    const starterGoals = [];
    const savedClassrooms = [];
    const records = [];

    if (i18n.language === "es") {
      initialData = {
        students: [...starterStudentsEs],
        positives: [...starterPositivesEs],
        negatives: [...starterNegativesEs],
        exchanges: [...starterExchangesEs],
        groups: [...starterGroups],
        goals: [...starterGoals],
        savedClassrooms: [...savedClassrooms],
        pergaminos: [...starterPergaminoES],
        settings: { ...starterSettings },
        dailySentences: [...starterDailySentenceEs],
        referredCount: 0,
      };
    }

    if (i18n.language === "cat") {
      initialData = {
        students: [...starterStudents],
        positives: [...starterPositives],
        negatives: [...starterNegatives],
        exchanges: [...starterExchanges],
        groups: [...starterGroups],
        goals: [...starterGoals],
        savedClassrooms: [...savedClassrooms],
        pergaminos: [...starterPergamino],
        settings: { ...starterSettings },
        dailySentences: [...starterDailySentence],
        referredCount: 0,
      };
    }

    await setDoc(docuRef, initialData);
    return initialData;
  }
}

export const fetchUserData = (userEmail) => async (dispatch) => {
  const fetchedData = await searchDocumentOrCreateDocument(userEmail);
  const docuRef = doc(firestore, `usuarios/${userEmail}`);
  if (fetchedData && fetchedData.students) {
    const studentsArray = Object.values(fetchedData.students);
    dispatch(setStudents(studentsArray));
    if (fetchedData.positives) {
      const positivesArray = Object.values(fetchedData.positives);
      dispatch(setPositives(positivesArray));
    }
    if (fetchedData.negatives) {
      const negativesArray = Object.values(fetchedData.negatives);
      dispatch(setNegatives(negativesArray));
    }
    if (fetchedData.exchanges) {
      const exchangesArray = Object.values(fetchedData.exchanges);
      dispatch(setExchanges(exchangesArray));
    }
    if (fetchedData.groups) {
      const groupsArray = Object.values(fetchedData.groups);
      dispatch(setGroups(groupsArray));
    }
    dispatch(setPets(starterPets));
    if (fetchedData.goals) {
      dispatch(setGoals(fetchedData.goals));
    }
    if (fetchedData.emails) {
      dispatch(setEmails(fetchedData.emails));
    }
    if (fetchedData.savedClassrooms) {
      dispatch(setSavedClassrooms(fetchedData.savedClassrooms));
    }
    if (fetchedData.records) {
      dispatch(setRecords(fetchedData.records));
    }
    if (fetchedData.pergaminos) {
      dispatch(setPergaminos(fetchedData.pergaminos));
    }
    if (fetchedData.settings) {
      dispatch(updateSettings(fetchedData.settings));
    }
    if (fetchedData.dailySentences) {
      dispatch(setSentences(fetchedData.dailySentences));
    }
    if (fetchedData.referredCount) {
      dispatch(setRefs(fetchedData.referredCount));
    }
    if (
      fetchedData.referredCount >= 3 &&
      fetchedData.settings.accountType !== "silver"
    ) {
      await dispatch(updateAccountType(userEmail, "silver"));
    }
  }
};

// add students
export const publishStudentsToDatabase =
  (userEmail, newStudents) => async (dispatch) => {
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    try {
      await updateDoc(docuRef, { students: newStudents });
      dispatch(addStudents(newStudents)); // Actualizando el estado en Redux.
    } catch (error) {
      console.error("Error al publicar estudiantes:", error);
    }
  };

export const updateStudentThunk = (payload) => async (dispatch, getState) => {
  const { id, formData, selectedImage, userEmail } = payload;

  const state = getState().students;

  const currentStudent = state.find((student) => student.id === id);
  if (!currentStudent) throw new Error("Estudiante no encontrado");

  const currentImage = currentStudent.img;
  const selectedImageValue = selectedImage ? selectedImage.value : currentImage;
  const img =
    selectedImageValue !== currentImage
      ? `../../images/chibits/${selectedImageValue}.png`
      : currentImage;

  const updatedStudent = {
    ...currentStudent,
    name: formData.get("name"),
    surname: formData.get("surname"),
    img: img,
  };

  const docuRef = doc(firestore, `usuarios/${userEmail}`);
  const matches = await getDoc(docuRef);
  if (!matches.exists()) throw new Error("Documento no encontrado");

  const docData = matches.data();
  docData.students[id] = updatedStudent;
  await updateDoc(docuRef, docData);

  // Luego de actualizar en Firebase, actualizamos en Redux
  dispatch(updateStudent({ id, updatedStudent }));
};

export const updateStudentPetThunk =
  (payload) => async (dispatch, getState) => {
    const { id, pet, userEmail } = payload;

    const state = getState().students;

    const currentStudent = state.find((student) => student.id === parseInt(id));

    if (!currentStudent) throw new Error("Estudiante no encontrado");

    const updatedStudent = {
      ...currentStudent,
      pet: pet,
    };

    dispatch(updateStudent({ id, updatedStudent }));

    const studentKey = `students.${id}`;
    const updateData = {
      [studentKey]: updatedStudent,
    };

    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, updateData);
  };

export const addBusinessToStudentThunk =
  (idStudent, businessId, userEmail) => async (dispatch, getState) => {
    const state = getState().students;
    const currentStudent = state.find(
      (student) => student.id === parseInt(idStudent)
    );

    if (!currentStudent) throw new Error("Estudiante no encontrado");

    // Update local state (optimistic update)
    const updatedStudentBusinesses = [
      ...(currentStudent.businesses || []),
      businessId,
    ];
    const updatedStudent = {
      ...currentStudent,
      businesses: updatedStudentBusinesses,
    };

    dispatch(updateStudent({ id: idStudent, updatedStudent }));

    // Actualizamos la base de datos en Firestore
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    const studentKey = `students.${idStudent}`;
    await updateDoc(docuRef, {
      [studentKey]: updatedStudent,
    });
  };

export const updateStudentPoints =
  (userEmail, studentId, count, actionName) => async (dispatch, getState) => {
    // Obtén el estado actual de los estudiantes desde Redux
    const currentStudents = getState().students;

    // Verifica si el estudiante existe en el estado
    if (currentStudents && currentStudents[studentId]) {
      // Actualiza los puntos en el estado local
      if (count > 0) {
        dispatch(addPoints({ id: studentId, points: count }));
      } else {
        dispatch(subtractPoints({ id: studentId, points: -count }));
      }

      const formatDate = (date) => {
        return date.toLocaleDateString("es-ES", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      };

      // Nueva entrada del records
      const newHistoryEntry = {
        date: formatDate(new Date()),
        amount: count,
        type: count > 0 ? "+" : "-",
        description: `${actionName}`,
      };

      dispatch(addRecord({ studentId, record: newHistoryEntry }));
      // Prepara los datos para actualizar en Firebase, incluyendo records
      const updatedStudentData = {
        ...currentStudents,
        [studentId]: {
          ...currentStudents[studentId],
          points: currentStudents[studentId].points + count,
          records: [
            ...(currentStudents[studentId].records || []),
            newHistoryEntry,
          ],
        },
      };

      // Actualiza los datos en Firebase en una sola operación
      const docuRef = doc(firestore, `usuarios/${userEmail}`);
      await updateDoc(docuRef, { students: updatedStudentData });
    }
  };

export const updateAllStudentPoints =
  (userEmail, amount, actionName) => async (dispatch, getState) => {
    const currentStudents = getState().students;

    let updates = {};
    Object.entries(currentStudents).forEach(([studentId, student]) => {
      //Check if it's number or string
      if (studentId !== "0" && studentId !== 0) {
        // Actualización de puntos
        const updatedPoints = student.points + amount;

        // Crear registro de historial
        const newHistoryEntry = {
          date: new Date().toLocaleDateString("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
          amount: Math.abs(amount),
          type: amount >= 0 ? "+" : "-",
          description: actionName,
        };
        dispatch(addRecord({ studentId, record: newHistoryEntry }));
        // Preparar actualización para Firebase
        updates[`students.${studentId}`] = {
          ...student,
          points: updatedPoints,
          records: [...(student.records || []), newHistoryEntry],
        };
      } else {
        // Solo actualizar los puntos para el estudiante con id 0
        updates[`students.${studentId}`] = {
          ...student,
          points: student.points + amount,
        };
      }
    });

    // Actualizar Firebase
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, updates);

    // Actualizar Redux
    dispatch(updateAllPoints(amount || 0));
  };

export const updateSelectedStudentsPoints =
  (userEmail, selectedStudents, pointsToAdd, actionName) =>
  async (dispatch, getState) => {
    const currentStudents = getState().students;

    let updates = {};
    currentStudents.forEach((student) => {
      if (selectedStudents.some((s) => s.id === student.id)) {
        // Crear registro de historial
        const newHistoryEntry = {
          date: new Date().toLocaleDateString("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
          amount: pointsToAdd,
          type: pointsToAdd >= 0 ? "+" : "-",
          description: actionName,
        };

        // Actualizar estudiante y añadir registro de historial
        updates[`students.${student.id}`] = {
          ...student,
          points: (student.points || 0) + pointsToAdd,
          records: [...(student.records || []), newHistoryEntry],
        };
        dispatch(addRecord({ studentId: student.id, record: newHistoryEntry }));
      }
    });

    // Actualizar Firestore
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    try {
      await updateDoc(docuRef, updates);

      // Actualizar el estado local en Redux
      const updatedStudents = currentStudents.map((student) =>
        selectedStudents.some((s) => s.id === student.id)
          ? updates[`students.${student.id}`]
          : student
      );
      dispatch(setStudents(updatedStudents));
    } catch (error) {
      console.error(
        "Error al actualizar los puntos de los estudiantes seleccionados:",
        error
      );
    }
  };

// reset all
export const resetAllStudentPoints =
  (userEmail) => async (dispatch, getState) => {
    // Resetear los puntos en el estado local
    dispatch(resetAllPoints());
    dispatch(resetAllRecords());
    dispatch(resetPet());
    dispatch(resetAllAchievements());
    dispatch(resetAllBusinesses());
    dispatch(resetAllDiamonds());
    const currentStudents = getState().students;

    const resetStudentData = { ...currentStudents };
    for (const studentId in resetStudentData) {
      resetStudentData[studentId] = {
        ...resetStudentData[studentId],
        points: 0,
        diamonds: 0,
        records: [],
        pet: "",
        businesses: [],
        achievements: [],
      };
    }

    // Actualizar los datos en Firebase
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, { students: resetStudentData });
  };

//Diamonds

export const updateStudentDiamonds =
  (userEmail, studentId, count, actionName) => async (dispatch, getState) => {
    // Obtén el estado actual de los estudiantes desde Redux
    const currentStudents = getState().students;

    // Verifica si el estudiante existe en el estado
    if (currentStudents && currentStudents[studentId]) {
      // Actualiza los diamantes en el estado local
      if (count > 0) {
        dispatch(addDiamonds({ id: studentId, diamonds: count }));
      } else {
        dispatch(subtractDiamonds({ id: studentId, diamonds: -count }));
      }

      const formatDate = (date) => {
        return date.toLocaleDateString("es-ES", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      };

      // Nueva entrada del records
      const newHistoryEntry = {
        date: formatDate(new Date()),
        amount: count,
        type: count > 0 ? "+" : "-",
        description: `${actionName}`,
      };

      dispatch(addRecord({ studentId, record: newHistoryEntry }));
      // Prepara los datos para actualizar en Firebase, incluyendo records
      const updatedStudentData = {
        ...currentStudents,
        [studentId]: {
          ...currentStudents[studentId],
          diamonds: (currentStudents[studentId].diamonds || 0) + count,
          records: [
            ...(currentStudents[studentId].records || []),
            newHistoryEntry,
          ],
        },
      };

      // Actualiza los datos en Firebase en una sola operación
      const docuRef = doc(firestore, `usuarios/${userEmail}`);
      await updateDoc(docuRef, { students: updatedStudentData });
    }
  };

export const updateAllStudentDiamonds =
  (userEmail, amount, actionName) => async (dispatch, getState) => {
    const currentStudents = getState().students;

    let updates = {};
    Object.entries(currentStudents).forEach(([studentId, student]) => {
      // Verificar si es un número o string
      if (studentId !== "0" && studentId !== 0) {
        // Actualización de diamantes
        const updatedDiamonds = (student.diamonds || 0) + amount;

        // Crear registro de historial
        const newHistoryEntry = {
          date: new Date().toLocaleDateString("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
          amount: Math.abs(amount),
          type: amount >= 0 ? "+" : "-",
          description: actionName,
        };
        dispatch(addRecord({ studentId, record: newHistoryEntry }));
        // Preparar actualización para Firebase
        updates[`students.${studentId}`] = {
          ...student,
          diamonds: updatedDiamonds,
          records: [...(student.records || []), newHistoryEntry],
        };
      } else {
        // Solo actualizar los diamantes para el estudiante con id 0
        updates[`students.${studentId}`] = {
          ...student,
          diamonds: (student.diamonds || 0) + amount,
        };
      }
    });

    // Actualizar Firebase
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, updates);

    // Actualizar Redux
    dispatch(updateAllDiamonds(amount || 0));
  };

export const updateSelectedStudentsDiamonds =
  (userEmail, selectedStudents, diamondsToAdd, actionName) =>
  async (dispatch, getState) => {
    const currentStudents = getState().students;

    let updates = {};
    currentStudents.forEach((student) => {
      if (selectedStudents.some((s) => s.id === student.id)) {
        // Crear registro de historial
        const newHistoryEntry = {
          date: new Date().toLocaleDateString("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
          amount: diamondsToAdd,
          type: diamondsToAdd >= 0 ? "+" : "-",
          description: actionName,
        };

        // Actualizar estudiante y añadir registro de historial
        updates[`students.${student.id}`] = {
          ...student,
          diamonds: (student.diamonds || 0) + diamondsToAdd,
          records: [...(student.records || []), newHistoryEntry],
        };
        dispatch(addRecord({ studentId: student.id, record: newHistoryEntry }));
      }
    });

    // Actualizar Firestore
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    try {
      await updateDoc(docuRef, updates);

      // Actualizar el estado local en Redux
      const updatedStudents = currentStudents.map((student) =>
        selectedStudents.some((s) => s.id === student.id)
          ? updates[`students.${student.id}`]
          : student
      );
      dispatch(setStudents(updatedStudents));
    } catch (error) {
      console.error(
        "Error al actualizar los diamantes de los estudiantes seleccionados:",
        error
      );
    }
  };

export const incrementReferralCountThunk =
  (invitedByEmail) => async (dispatch) => {
    const docRef = doc(firestore, `usuarios/${invitedByEmail}`);
    try {
      await runTransaction(firestore, async (transaction) => {
        const docSnap = await transaction.get(docRef);
        if (!docSnap.exists()) {
          throw new Error("El documento no existe.");
        }
        const currentReferredCount = docSnap.data().referredCount || 0;
        const newReferredCount = currentReferredCount + 1;

        transaction.update(docRef, { referredCount: newReferredCount });
      });
      console.log("Se ha incrementado el contador de referidos exitosamente.");
      // Retornar un valor que indica éxito
      return Promise.resolve();
    } catch (error) {
      console.error("Error al incrementar el contador de referidos:", error);
      // Propagar el error para que pueda ser capturado en el componente
      return Promise.reject(error);
    }
  };
